import axios, { AxiosError, HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, redirect, useParams } from 'react-router-dom';
import { Url } from './Models/Url';
import NotFound from './NotFound';

function Shotu() {
    const {urlHash} = useParams();
    const [urlObj, setUrlObj] = useState<Url>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const [rootAPI, setRootAPI] = React.useState<string>();

    React.useEffect(() =>
        {
          if(process.env.NODE_ENV == 'development')
          {
            setRootAPI(process.env.REACT_APP_DEV_API_ROOT);
          }
          else
          {
            setRootAPI(process.env.REACT_APP_PROD_API_ROOT);
          }
        }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(urlHash && rootAPI)
                    {
                        var apiString =  rootAPI + urlHash.toString();
                        const obj = await axios.get<Url>(apiString);
                        if(obj.status == 200)
                        {
                            setUrlObj(obj.data);
                        }
                        else
                            setError(obj.statusText);
                    } 
            }
            catch (err){
                const axioserr = err as AxiosError;
                setError(axioserr.response?.statusText || 'An error occurred');
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
        
      }, [loading])
    
      if(loading)
      {
        return <h1>Fetching URL....</h1>
      }

      if (error)
      {
        return <NotFound />
      }

      if(!loading && urlObj)
      {
            window.location.href = urlObj.longUrl;
            
      }

      return <div></div>
    }

export default Shotu;